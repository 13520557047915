import { Pagination, Spin } from "antd";
import { get, map } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateActiveId } from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { formatDecimalNumber } from "../../../utilities/common-function";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getUserSummary from "../../../newApi/pairing/getUserSummary";
import getUserTransactions from "../../../newApi/pairing/getUserTransactions";
import { numberDecimal } from "../../../utilities/startUp";

const PAGE_SIZE = 10;

//let displayRobot = [];

// markup
const DirectReward = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [todayData, setTodayData] = useState({});
  const [totalData, setTotalData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  //let profile = get(props.user.user, "profile");
  const user = get(props, "user.user");

  useEffect(() => {
    getTotalData();
    getData((page - 1) * PAGE_SIZE);
  }, []);

  useEffect(() => {
    getData((page - 1) * PAGE_SIZE);
  }, [page]);

  function getData(skip) {
    // setArrayLoading(true);
    setLoading(true);
    getUserTransactions(PAGE_SIZE, skip, {
      userId: get(user, "pairingUser._id"),
      sourceTypein: [10, 11],
      amountType: 0,
      // populate: [
      //   {
      //     service: "bonus",
      //     foreignId: "bonusId",
      //     populate: [
      //       {
      //         service: "rankings",
      //         foreignId: "rankingId",
      //       },
      //       {
      //         service: "users",
      //         foreignId: "buyerId",
      //       },
      //     ],
      //   },
      // ],
    })
      .then((res) => {
        // console.log("Res", res);
        let data = get(res, "data");
        setTotal(get(res, "total"));
        setDataSource(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function getMonthWord(month) {
    switch (month) {
      case 1:
        return t("jan", sourceKey.pairing);
        break;
      case 2:
        return t("feb", sourceKey.pairing);
        break;
      case 3:
        return t("mar", sourceKey.pairing);
        break;
      case 4:
        return t("apr", sourceKey.pairing);
        break;
      case 5:
        return t("may", sourceKey.pairing);
        break;
      case 6:
        return t("june", sourceKey.pairing);
        break;
      case 7:
        return t("july", sourceKey.pairing);
        break;
      case 8:
        return t("aug", sourceKey.pairing);
        break;
      case 9:
        return t("sept", sourceKey.pairing);
        break;
      case 10:
        return t("oct", sourceKey.pairing);
        break;
      case 11:
        return t("nov", sourceKey.pairing);
        break;
      case 12:
        return t("dec", sourceKey.pairing);
        break;
      default:
        break;
    }
  }

  function getTotalData() {
    // setArrayLoading(true);
    // console.log(user)
    // console.log(get(user, "user.pairingUser._id"));
    if (get(user, "pairingUser._id")) {
      // console.log("getData");
      getUserSummary("all", 0, {
        userId: get(user, "pairingUser._id"),
        dateFrom: moment().startOf("d").toDate(),
        dateTo: moment().endOf("d").toDate(),
      })
        .then((res) => {
          // console.log({ res });
          setTodayData(get(res, "data"));
        })
        .catch((err) => {
          console.log(err);
        });

      getUserSummary("all", 0, {
        userId: get(user, "pairingUser._id"),
      })
        .then((res) => {
          // console.log({ res });
          setTotalData(get(res, "data"));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const _renderProfit = () => {
    return (
      <div className="grid grid-cols-4 gap-2 bg-main-color-gradient rounded-3xl mx-4 pt-2 pb-1">
        <div className="col-span-2 ">
          <div className="mb-2 ">
            <div className="font-semibold text-center text-2xl pt-4">
              {formatDecimalNumber(
                get(todayData, "totalCompanyBonus") || 0,
                numberDecimal
              )}
            </div>
            <div className="pb-1.5 pt-1  rounded-md p-2 sm:px-10 mr-2 text-center">
              {/* 今日盈利 <br /> */}
              {t("profitToday", sourceKey.pairing)}
            </div>
          </div>
        </div>

        <div className="col-span-2">
          <div className="mb-2 ">
            <div className="font-semibold text-center text-2xl pt-4">
              {formatDecimalNumber(
                get(totalData, "totalCompanyBonus") || 0,
                numberDecimal
              )}
            </div>
            <div className="pb-1.5 pt-1  rounded-md p-2 sm:px-10 mr-2 text-center">
              {/* 总盈利 <br /> */}
              {t("totalProfit", sourceKey.pairing)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderList = () => {
    return (
      <div className="greybox-bg-color m-4 rounded-b-3xl mt-2">
        {map(dataSource, (item, index) => {
          return (
            <div key={index} className=" mx-2  pb-2">
              <div className="px-4">
                <div className="flex justify-between ">
                  <span className="flex items-center">
                    {get(item, "sourceType") === 10
                      ? t("vip1RewardFor", sourceKey.pairing)
                      : t("vip2RewardFor", sourceKey.pairing)}
                    {/* {t("vipRewardFor", sourceKey.pairing)}{" "} */}
                    {getMonthWord(
                      moment(get(item, "createdAt")).month() + 1
                    )}{" "}
                    {moment(get(item, "createdAt")).format("YYYY")}
                  </span>
                  <span className="flex items-center">
                    + {formatDecimalNumber(item.amount, numberDecimal)}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            showBack={true}
            suffix={
              <div className="justify-end flex items-center">
                {t("vipReward", sourceKey.pairing)}
              </div>
            }
          >
            <div
              className=""
              // style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
            >
              <div className="">{_renderProfit()}</div>
              <div>{_renderList()}</div>
              <div className="flex justify-end items-center my-5">
                <span className="inline-block ">
                  <Pagination
                    simple
                    size="small"
                    total={total}
                    pageSize={PAGE_SIZE}
                    current={page}
                    showSizeChanger={false}
                    responsive={true}
                    onChange={(page) => {
                      setPage(page);
                    }}
                  />
                </span>
              </div>
            </div>
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  updateActiveId,
};
export default connect(mapStateToProps, mapDispatchToProps)(DirectReward);
